<!--
 * @Description: 主要功能
 * @Author: FCC
 * @Date: 2019-08-06 08:45:20
 * @LastEditors: 小广
 * @LastEditTime: 2020-06-10 17:43:12
 -->
<template>
  <div class="roleMgrList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :selectable="selectable"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
        <v-button text="批量授权" permission="add" @click="goPageBatch(1)"></v-button>
        <v-button text="批量收权" permission="add" @click="goPageBatch(0)"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="角色名" v-model="searchParams.roleName"></v-input>
        <v-select label="角色类型" v-model="searchParams.roleType" :options="roleTypeOps"></v-select>
        <v-select2 label="角色归属" v-model="searchParams.orgName" :subjoin="roleExtraParams" v-bind="orgParams"></v-select2>
        <!-- <v-select label="权限分类" v-model="searchParams.level1MenuId" :options="level1MenuIdOps"></v-select> -->
        <!-- RONG-3035 按照产品要求先隐藏这个搜索设置 -->
        <!-- <div v-if="searchParams.level1MenuId" class="authLabel"><span class="left">权限选择：</span><span class="right" @click="toSetAuth">设置</span></div> -->
      </template>
      <template #operateSlot="scope">
        <v-button v-if="update || editr" text="编辑" type="text" @click="edit(scope.row)"></v-button>
        <a v-if="deleter || off">
          <a v-if="scope.row.editable && scope.row.deleteable && scope.row.copyable">
            <el-dropdown trigger="click" @command="moreHandle">
              <span class="el-dropdown-link">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ row: scope.row, type: 'delete' }">
                  <span>删除</span>
                </el-dropdown-item>
                <el-dropdown-item :command="{ row: scope.row, type: 'copy' }">
                  <span>复制角色</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </a>
          <a v-else>
            <v-button v-if="scope.row.hide === 0" text="删除" type="text" @click="deleteRole(scope.row)"></v-button>
            <v-button v-if="scope.row.copyable" text="复制角色" type="text" @click="copyRole(scope.row)"></v-button>
          </a>
        </a>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量删除" :disabled="scope.selectedData.ids.length === 0" permission="delete" @click="batch(scope.selectedData)"></v-button>
      </template>
    </list>
    <Auth-select :menuData="menuData" :title="authDialogTitle" :isShow.sync="authSelectShow" @callBack="AuthBack"></Auth-select>
  </div>
</template>

<script>
import { getListURL, exportListURL, getOrgListURL, getRoleTypeURL, delRolesURL, getMenuResourceURL } from './api'
import { level1MenuIdMap, setLevel1MenuIdOps } from './map'
import { generateMapByOpts } from 'common/utils'
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import AuthSelect from './AuthSelect.vue'
export default {
  name: 'RoleMgrList',
  components: {
    elDropdown: Dropdown,
    elDropdownMenu: DropdownMenu,
    elDropdownItem: DropdownItem,
    AuthSelect
  },
  data () {
    let _this = this
    return {
      level1MenuIdOps: setLevel1MenuIdOps(1),
      searchUrl: getListURL,
      exportUrl: exportListURL,
      roleTypeOps: [],
      roleTypeMap: {},
      orgParams: {
        searchUrl: getOrgListURL,
        request: {
          text: 'orgName_',
          value: 'orgName_'
        },
        response: {
          text: 'name',
          value: 'name'
        }
      },
      searchParams: {
        roleName: '',
        roleType: undefined,
        orgName: '',
        level1MenuId: undefined,
        level4MenuIds: ''
      },
      headers: [
        {
          prop: 'roleName',
          label: '角色名'
        },
        {
          prop: 'roleTypeText',
          label: '角色类型',
          formatter (row) {
            return _this.roleTypeMap[row.roleType]
          }
        },
        {
          prop: 'orgName',
          label: '角色归属'
        },
        {
          prop: 'createTime',
          label: '创建时间'
        },
        {
          prop: 'userCnt',
          label: '关联用户',
          align: 'right'
        }
      ],
      menuList: [],
      authSelectShow: false,
      menuData: []
    }
  },
  computed: {
    roleExtraParams () {
      return {
        roleType: this.searchParams.roleType
      }
    },
    update () {
      return this.$store.getters.getPermission('update')
    },
    editr () {
      return this.$store.getters.getPermission('edit')
    },
    deleter () {
      return this.$store.getters.getPermission('delete')
    },
    off () {
      return this.$store.getters.getPermission('off')
    },
    authDialogTitle () {
      return level1MenuIdMap[this.searchParams.level1MenuId]
    }
  },
  mounted () {
    this.getRoleType()
    this.getMenuResource()
  },
  methods: {
    selectable (row, index) {
      return row.deleteable
    },
    getRoleType () {
      this.$axios.get(getRoleTypeURL).then(res => {
        if (res.status === '100') {
          const { data } = res
          this.roleTypeOps = data.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          })
          this.roleTypeMap = generateMapByOpts(this.roleTypeOps)
          !this.searchParams.roleType && (this.searchParams.roleType = data[0].id)
        }
      })
    },
    updateStatus (ids, dataObjects) {
      // 操作日志添加操作对象 规则：角色名称-归属层级
      let dataObject = dataObjects.join(';')
      let postData = {
        roleIds: ids,
        dataObject: dataObject
      }

      this.$axios.post(delRolesURL, postData).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message.success('操作成功')
        }
      })
    },
    async batch (selected) {
      let dataObjects = []
      selected.data.forEach(item => {
        dataObjects.push(`${item.roleName}-${item.orgName}`)
      })

      let ids = selected.ids
      let isOk = await this.$confirm('确定执行该操作？')
      isOk && this.updateStatus(ids, dataObjects)
    },
    create () {
      this.$router.push({
        name: 'roleMgrForm'
      })
    },
    goPageBatch (val) {
      this.$router.push({
        name: 'batchEmpower',
        query: {
          opera: val
        }
      })
    },
    edit (row) {
      this.$router.push({
        name: 'roleMgrForm',
        query: {
          id: row.id,
          orgId: row.orgId,
          roleType: row.roleType,
          isSystemRole: row.isSystemRole,
          isCommon: row.isCommon
        }
      })
    },
    moreHandle (val) {
      if (val.type === 'delete') {
        this.deleteRole(val.row)
      } else if (val.type === 'copy') {
        this.copyRole(val.row)
      }
    },
    async deleteRole (row) {
      let isOk = await this.$confirm('确认删除？')
      isOk && this.updateStatus([row.id], [`${row.roleName}-${row.orgName}`])
    },
    copyRole (row) {
      this.$router.push({
        name: 'roleMgrForm',
        query: {
          copyId: row.id,
          orgId: row.orgId,
          roleType: row.roleType,
          isSystemRole: row.isSystemRole,
          isCommon: row.isCommon
        }
      })
    },
    getMenuResource () {
      this.$axios(getMenuResourceURL).then(res => {
        if (res.status === 100) {
          let menuList = []
          res.data.forEach(item => {
            menuList = [
              ...menuList,
              ...item.childMenus
            ]
          })
          this.menuList = menuList
        }
      })
    },
    toSetAuth () {
      let level1Id = this.searchParams.level1MenuId
      let list = this.menuList.filter(item => {
        return item.id === level1Id
      })[0].childMenus
      this.menuData = list
      this.authSelectShow = true
    },
    AuthBack (val) {
      this.searchParams.level4MenuIds = val.join(',')
    }
  }
}
</script>
<style lang="scss" scoped>
.authLabel {
  width: 261px;
  height: 40px;
  line-height: 40px;
  display: flex;
  .left {
    flex: 1;
    text-align: right
  }
  .right {
    display: block;
    width: 144px;
    text-align: left;
    color: #1B8CF2;
    cursor: pointer;
  }
}
</style>
