var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "roleMgrList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          isMultiSelect: true,
          selectable: _vm.selectable,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
                _c("v-button", {
                  attrs: { text: "批量授权", permission: "add" },
                  on: {
                    click: function ($event) {
                      return _vm.goPageBatch(1)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "批量收权", permission: "add" },
                  on: {
                    click: function ($event) {
                      return _vm.goPageBatch(0)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "角色名" },
                  model: {
                    value: _vm.searchParams.roleName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "roleName", $$v)
                    },
                    expression: "searchParams.roleName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "角色类型", options: _vm.roleTypeOps },
                  model: {
                    value: _vm.searchParams.roleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "roleType", $$v)
                    },
                    expression: "searchParams.roleType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "角色归属",
                        subjoin: _vm.roleExtraParams,
                      },
                      model: {
                        value: _vm.searchParams.orgName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgName", $$v)
                        },
                        expression: "searchParams.orgName",
                      },
                    },
                    "v-select2",
                    _vm.orgParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _vm.update || _vm.editr
                  ? _c("v-button", {
                      attrs: { text: "编辑", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.edit(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.deleter || _vm.off
                  ? _c("a", [
                      scope.row.editable &&
                      scope.row.deleteable &&
                      scope.row.copyable
                        ? _c(
                            "a",
                            [
                              _c(
                                "el-dropdown",
                                {
                                  attrs: { trigger: "click" },
                                  on: { command: _vm.moreHandle },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v("\n              更多"),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            command: {
                                              row: scope.row,
                                              type: "delete",
                                            },
                                          },
                                        },
                                        [_c("span", [_vm._v("删除")])]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            command: {
                                              row: scope.row,
                                              type: "copy",
                                            },
                                          },
                                        },
                                        [_c("span", [_vm._v("复制角色")])]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "a",
                            [
                              scope.row.hide === 0
                                ? _c("v-button", {
                                    attrs: { text: "删除", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRole(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              scope.row.copyable
                                ? _c("v-button", {
                                    attrs: { text: "复制角色", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyRole(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    text: "批量删除",
                    disabled: scope.selectedData.ids.length === 0,
                    permission: "delete",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batch(scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("Auth-select", {
        attrs: {
          menuData: _vm.menuData,
          title: _vm.authDialogTitle,
          isShow: _vm.authSelectShow,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.authSelectShow = $event
          },
          "update:is-show": function ($event) {
            _vm.authSelectShow = $event
          },
          callBack: _vm.AuthBack,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }