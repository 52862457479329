<template>
  <div class="authSelect-container">
    <el-dialog
      title="项目列表"
      :visible.sync="dialogShow"
      :show-close="false"
      :close-on-click-modal="true"
      width="75%"
      top="40px"
      @closed="closeDialog"
    >
      <div slot="title" class="topStyle">
        <span>{{title}}</span>
        <i class="el-icon-close pointer" @click="closeDialog"></i>
      </div>
      <div class="multiSelect-content">
        <div class="left">
          <div class="menu-wrapper">
            <div>
              <v-checkbox v-model="allShow" label="全部显示" @change="allInShow"></v-checkbox>
            </div>
            <div class="menu-list" v-for="(item, index) in menuList" :class="{ active: item.show }" :key="index" @click="parentMenu(item)">{{item.name}}</div>
          </div>
          <div>
            <div class="allChoose" v-if="allSelectShow">
              <span class="greendot"></span>
              <span class="label">权限配置</span>
              <v-checkbox v-model="allSelect" label="全部勾选" @change="allToSelect"></v-checkbox>
            </div>
            <div v-for="(item, index) in menuList" :key="index">
              <div v-if="item.show">
                <div class="h1">
                  <span>{{item.name}}</span>
                  <v-checkbox v-model="item.selected" @change="(val) => {return modeAllSelect(val, item, index)}"></v-checkbox>
                </div>
                <div class="marTB15 flexBox">
                  <div class="menu" v-for="(childItem, childIndex) in item.childMenus" :key="childIndex">
                    <v-checkbox v-model="childItem.selected" @change="(val) => {return menuSelect(val, childItem, childIndex)}"></v-checkbox>
                    <div>
                      <div>{{childItem.childIndex ? childItem.parentName : childItem.name}}</div>
                      <div v-if="childItem.childIndex">{{childItem.childIndex}}{{childItem.name}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="r-heard">
            <v-button text="清空" type="default" @click="allToSelect(false)"></v-button>
          </div>
          <div class="r-middle">
            <li
              class="list-box"
              v-for="(item, index) in selectedData"
              :key="index"
            >
              <span>{{item.name}}</span>
              <i
                class="el-icon-close pointer i-lineHeight"
                @click="cancelSelected(item, index)"
              ></i>
            </li>
          </div>
          <div class="r-footer">
            <div>
              已选择：
              <span>{{selectedData.length}}</span>条
            </div>
            <v-button text="确定" @click="submit"></v-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { vCheckbox } from 'components/control/index'
export default {
  name: 'auth-select',
  components: {
    vCheckbox
  },
  props: {
    // 是否显示控件
    isShow: Boolean,
    // 组件标题
    title: {
      type: String,
      // `''`
      default: ''
    },
    menuData: Array
  },
  data () {
    return {
      dialogShow: false,
      menuList: [],
      selectedData: [],
      allShow: false,
      allSelect: false
    }
  },
  computed: {
    allSelectShow () {
      let isHas = false
      this.menuList.forEach(item => {
        item.show && (isHas = true)
      })
      return isHas
    }
  },
  methods: {
    closeDialog () {
      this.$emit('update:isShow', false)
    },
    intiData () {
      let menuList = []
      this.selectedData = []
      this.allShow = false
      this.allSelect = false
      menuList = this.menuData.map(item => {
        let chlidMenus = []
        item.childMenus.forEach(child => {
          if (child.childMenus.length === 1) {
            let childOps = {
              name: child.name,
              selected: false,
              id: child.childMenus[0].id
            }
            chlidMenus.push(childOps)
          } else if (child.childMenus.length > 1) {
            let childOpsList = child.childMenus.map((thirdChild, thirdIndex) => {
              return {
                name: thirdChild.name,
                selected: false,
                parentName: child.name,
                childIndex: thirdIndex + 1,
                id: thirdChild.id
              }
            })
            chlidMenus = [
              ...chlidMenus,
              ...childOpsList
            ]
          }
        })
        return {
          name: item.name,
          selected: false,
          show: false,
          childMenus: chlidMenus
        }
      })
      this.menuList = menuList
    },
    parentMenu (item) {
      item.show = !item.show
      if (!item.show) {
        // 123
        let ids = item.childMenus.map(item => item.id)
        item.childMenus.forEach(item => {
          item.selected = false
        })
        this.selectedData = this.selectedData.filter(value => ids.indexOf(value.id) < 0)
      }
      this.checkAllBtn()
    },
    menuSelect (val, item, index) {
      // 选中
      if (val) {
        this.selectedData.push(item)
      } else {
        this.selectedData = this.selectedData.filter(value => value.id !== item.id)
      }
      this.checkAllBtn()
    },
    // 全显示、全不显示
    allInShow (val) {
      this.menuList.forEach(item => {
        item.show = val
        if (!val) {
          let ids = item.childMenus.map(item => item.id)
          item.childMenus.forEach(item => {
            item.selected = false
          })
          this.selectedData = this.selectedData.filter(value => ids.indexOf(value.id) < 0)
        }
      })
      this.checkAllBtn()
    },
    // 全选、全取消
    allToSelect (val) {
      let selectedData = []
      this.menuList.forEach(item => {
        item.childMenus.forEach(childItem => {
          if (item.show) {
            childItem.selected = val
            val && selectedData.push(childItem)
          }
        })
      })
      this.selectedData = selectedData
      this.checkAllBtn()
    },
    // 模块全选、全取消
    modeAllSelect (val, item, index) {
      if (val) {
        let selectedIds = this.selectedData.map(item => item.id)
        item.childMenus.forEach(childItem => {
          childItem.selected = true
          if (selectedIds.indexOf(childItem.id) < 0) {
            this.selectedData.push(childItem)
          }
        })
      } else {
        item.childMenus.forEach(childItem => {
          childItem.selected = false
        })
        let ids = item.childMenus.map(item => item.id)
        this.selectedData = this.selectedData.filter(value => ids.indexOf(value.id) < 0)
      }
      this.checkAllBtn()
    },
    cancelSelected (item, index) {
      this.menuList.forEach(items => {
        items.childMenus.forEach(childItem => {
          if (childItem.id === item.id) {
            childItem.selected = false
          }
        })
      })
      this.selectedData.splice(index, 1)
      this.checkAllBtn()
    },
    checkAllBtn () {
      let allShow = true
      let allSelect = true
      this.$nextTick(() => {
        this.menuList.forEach(item => {
          !item.show && (allShow = false)
          if (item.show) {
            let selected = true
            item.childMenus.forEach(childItem => {
              if (!childItem.selected) {
                selected = false
                allSelect = false
              }
            })
            item.selected = selected
          }
        })
        this.allShow = allShow
        this.allSelect = allSelect
      })
    },
    submit () {
      let ids = this.selectedData.map(item => item.id)
      this.$emit('update:isShow', false)
      this.$emit('callBack', ids)
    }
  },
  watch: {
    isShow (val) {
      this.dialogShow = val
      if (val) {
        this.intiData()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.authSelect-container {
  text-align: left;
  .topStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      font-size: 18px;
    }
  }
}
.multiSelect-content {
  display: flex;
  flex-direction: row;
  height: 750px;
  .left {
    // display: flex;
    // flex-direction: column;
    flex: 1;
    padding-right: 10px;
    overflow: auto;
  }
  .right {
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 200px;
    border-left: 1px solid #dedede;
    .r-heard {
      padding: 0 10px 10px;
      border-bottom: 1px solid #dedede;
      text-align: right;
    }
    .r-middle {
      flex: 1;
      // max-height: 729px;
      overflow: auto;
      .list-box {
        padding: 15px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
          line-height: 36px;
        }
      }
      .list-box:hover {
        background-color: #d7f2fb;
        color: #3ebbf0;
      }
    }
    .r-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 10px 0;
      align-items: center;
      span: {
        margin: 0 3px;
        font-weight: 700;
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
.menu-wrapper {
  // width: 780px;
  padding: 10px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  overflow: hidden;
  .menu-list {
    float: left;
    min-width: 80px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid rgb(237, 236, 242);
    margin: 15px 0 0 20px;
    padding: 0 10px 0 40px;
    color: rgb(0, 0, 0);
    background: url(./img/invisible.png) no-repeat 12px 8px;
  }
  .active {
    color: #fff;
    background: url(./img/visible.png) no-repeat 12px 8px #1b8cf2;
  }
}
.allChoose {
  display: flex;
  height: 40px;
  line-height: 40px;
  .greendot {
    display: inline-block;
    width: 10px;
    height: 10px;
    padding: 0;
    background-color: #7ac943;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: 15px;
  }
  .label {
    vertical-align: bottom;
    padding-right: 12px;
  }
}
.h1 {
  display: flex;
  span {
    color: #000;
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.marTB15{
  margin: 15px 0;
}
.flexBox {
  display: flex;
  flex-wrap: wrap;
  .menu {
    display: flex;
    border: 1px solid #dedede;
    border-radius: 4px;
    width: 160px;
    padding: 0 10px;
    box-sizing: border-box;
    height: 50px;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.i-lineHeight {
  line-height: 36px;
}
</style>
