// 获取列表
const getListURL = `${API_CONFIG.controlBaseURL}sysRole/pageListSysRole`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}role/exportRolePermission`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.controlBaseURL}sysRole/getOrganize`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 角色类型列表
const getRoleTypeURL = `${API_CONFIG.baseURL}serverUserAction!getRoleType.action`
// 新增角色
const addRoleURL = `${API_CONFIG.controlBaseURL}sysRole/insertSysRole`
// 编辑
const updataRoleURL = `${API_CONFIG.controlBaseURL}sysRole/updateSysRole`
// 获取角色信息
const getRoleDetailURL = `${API_CONFIG.controlBaseURL}sysRole/viewRoleMenu`
// 获取权限菜单（新增）
const getViewMenuURL = `${API_CONFIG.controlBaseURL}sysRole/viewMenu`
// 获取角色列表
const getListRoleURL = `${API_CONFIG.baseURL}serverRoleAction!listRole.action`
// 角色列表
const getOrganizeURL = `${API_CONFIG.controlBaseURL}sysRole/getOrganizeEdit`
// 批量授权
const batchAuthURL = `${API_CONFIG.butlerBaseURL}sysRole/batchGrant`
// 批量收权
const batchDeAuthURL = `${API_CONFIG.butlerBaseURL}sysRole/batchRevoke`
// 批量授权
const delRolesURL = `${API_CONFIG.controlBaseURL}sysRole/delRoles`
// 批量授权
const getMenuResourceURL = `${API_CONFIG.controlBaseURL}sysRole/menuResource?subPlatformId=0`
export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getRoleTypeURL,
  addRoleURL,
  updataRoleURL,
  getRoleDetailURL,
  getViewMenuURL,
  getListRoleURL,
  getOrganizeURL,
  batchAuthURL,
  batchDeAuthURL,
  delRolesURL,
  getMenuResourceURL
}
